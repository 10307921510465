const actions = {
  CLIENT_PROFILES_LIST_UPDATE_BEGIN: 'CLIENT_PROFILES_LIST_UPDATE_BEGIN',
  CLIENT_PROFILES_LIST_UPDATE_SUCCESS: 'CLIENT_PROFILES_LIST_UPDATE_SUCCESS',
  CLIENT_PROFILES_LIST_UPDATE_ERR: 'CLIENT_PROFILES_LIST_UPDATE_ERR',

  clientProfilesListUpdateBegin: () => {
    return {
      type: actions.CLIENT_PROFILES_LIST_UPDATE_BEGIN,
    };
  },

  clientProfilesListUpdateSuccess: data => {
    return {
      type: actions.CLIENT_PROFILES_LIST_UPDATE_SUCCESS,
      data,
    };
  },

  clientProfilesListUpdateErr: err => {
    return {
      type: actions.CLIENT_PROFILES_LIST_UPDATE_ERR,
      err,
    };
  },

  CLIENT_PROFILES_LIST_CLEANUP_BEGIN: 'CLIENT_PROFILES_LIST_CLEANUP_BEGIN',
  CLIENT_PROFILES_LIST_CLEANUP_SUCCESS: 'CLIENT_PROFILES_LIST_CLEANUP_SUCCESS',
  CLIENT_PROFILES_LIST_CLEANUP_ERR: 'CLIENT_PROFILES_LIST_CLEANUP_ERR',

  clientProfilesListCleanupBegin: () => {
    return {
      type: actions.CLIENT_PROFILES_LIST_CLEANUP_BEGIN,
    };
  },

  clientProfilesListCleanupSuccess: () => {
    return {
      type: actions.CLIENT_PROFILES_LIST_CLEANUP_SUCCESS,
    };
  },

  clientProfilesListCleanupErr: err => {
    return {
      type: actions.CLIENT_PROFILES_LIST_CLEANUP_ERR,
      err,
    };
  },
};

export default actions;
