import { ClearOutlined } from '@ant-design/icons';
import { Image, Skeleton, Space, Tag } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InfoWraper, UserProfilePopover } from './auth-info-style';
import Newsletter from './newsletter';
import Notification from './notification';
import UserEfficiency from './userEfficiency';
import { Button } from '../../layout/buttons/buttons';
import { Popover } from '../../layout/popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import { emptyPhoto, renderPicture } from '../../../container/users/functions';
import { Copyable, listGroup } from '../utilities';
import { renderUserType, translateUserDepartment } from '../../users/functions';
import confirmAction from '../../confirmAction/confirmAction';
import { cacheClearAllAction } from '../../../redux/cache/actionCreator';
import { isUserACLAllowed, isUserEmployee } from '../../../config/acl/functions';
import { renderAchievements, renderBalance } from '../../achievements/functions';
import { theme } from '../../../config/theme/themeVariables';
import { EntityCard } from '../../../container/styled-custom';

const AuthInfo = () => {
  const dispatch = useDispatch();

  const { identity, user, isLoading } = useSelector(state => {
    return {
      identity: state.auth.identity,
      user: state.auth.identity.data?.user,
      isLoading: state.auth.identity.loading,
    };
  });

  const [isClient, setIsClient] = useState(undefined);
  useEffect(() => {
    setIsClient(user ? !isUserEmployee(user) : undefined);
  }, [user]);

  // SignOut
  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  // userPopover
  const userPopover = (
    <UserProfilePopover>
      <div className="user-popover">
        {isLoading || !user ? (
          <Space direction="vertical">
            <Skeleton.Avatar active size={48} />
            <Skeleton active paragraph={{ rows: 4 }} />
          </Space>
        ) : (
          <Space direction="vertical" style={{ width: '100%' }}>
            <div className="user-popover__info">
              <EntityCard bordered={false}>
                <EntityCard.Meta
                  avatar={
                    <Image
                      src={user?.photo_url ?? emptyPhoto}
                      alt={user.name}
                      fallback={emptyPhoto}
                      preview={!!user.photo_url}
                      rootClassName="border-rounded"
                      className="border-rounded"
                      style={{ backgroundColor: theme['white-color'] }}
                      width={64}
                      height={64}
                    />
                  }
                  title={
                    <Space>
                      {user.name}
                      {renderUserType(user)}
                      {!!user?.external && <Tag color="processing">Externo</Tag>}
                    </Space>
                  }
                  description={
                    <Space direction="vertical" style={{ width: '100%' }} size="small">
                      {user?.users_departments !== undefined && (
                        <div className="user-departments">
                          {user?.users_departments && (
                            <>
                              {isClient && 'Cliente em '}
                              {listGroup(
                                user?.users_departments // setor, setor...
                                  .map(users_department =>
                                    isClient
                                      ? users_department.department.name
                                      : translateUserDepartment(users_department),
                                  ),
                                2,
                              )}
                            </>
                          )}
                        </div>
                      )}

                      <div className="user-email">
                        <Copyable copyable={{ text: user.email }}>{user.email}</Copyable>
                      </div>

                      {renderAchievements(user?.achievements)}

                      {!isClient && renderBalance(user?.achievement_balance, 'flex-start')}
                    </Space>
                  }
                />
              </EntityCard>
            </div>

            <ul className="user-popover__links">
              <li>
                <Link to="/admin/users/profile">
                  <FeatherIcon icon="user" /> Perfil
                </Link>
              </li>
              {isUserACLAllowed(identity, '/admin/cache/clearAll') && (
                <li>
                  <Link
                    to="#"
                    className="text-danger"
                    onClick={() => {
                      confirmAction({
                        title: 'Tem certeza que deseja limpar o cache?',
                        okText: 'Limpar Cache',
                        onOk: () => dispatch(cacheClearAllAction()),
                      });
                    }}
                  >
                    <ClearOutlined /> Limpar Cache
                  </Link>
                </li>
              )}
            </ul>
          </Space>
        )}

        <Button type="danger" className="user-popover__bottomAction" onClick={SignOut}>
          <FeatherIcon icon="log-out" /> Sair
        </Button>
      </div>
    </UserProfilePopover>
  );

  return (
    <InfoWraper>
      <UserEfficiency />
      {/* <Message /> */}
      <Notification />
      <Newsletter />

      <div className="chatgpt" style={{ margin: '0 12px' }}>
        <Link to="/admin/chatgpt" title="Secran Brain">
          <svg
            style={{ width: 20, height: 20, verticalAlign: 'sub' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#FFF"
              d="M184 0c30.9 0 56 25.1 56 56l0 400c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56l0-400c0-30.9 25.1-56 56-56z"
            />
          </svg>
        </Link>
      </div>

      <div className="help">
        <Link to={{ pathname: 'https://wiki.secran.digital/' }} title="Ajuda" target="_blank">
          <FeatherIcon icon="help-circle" size={20} />
        </Link>
      </div>

      <div className="nav-author">
        {isLoading === true || !user ? (
          <Skeleton.Avatar active />
        ) : (
          <Popover placement="bottomRight" content={userPopover} action="hover">
            <Link to="#">{renderPicture(user, 'default')}</Link>
          </Popover>
        )}
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
