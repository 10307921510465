import { BankOutlined, RobotOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { isUserACLAllowed } from '../config/acl/functions';

const MenuAdmin = ({ toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const { identity } = useSelector(state => {
    return {
      identity: state.auth.identity,
    };
  });

  return (
    <>
      <Menu.ItemGroup key="principal" title="Principal">
        <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="home">
          <NavLink onClick={toggleCollapsed} to={`${path}`}>
            Dashboard
          </NavLink>
        </Menu.Item>
        <Menu.SubMenu key="principal-arquivos" icon={!topMenu && <FeatherIcon icon="hard-drive" />} title="Arquivos">
          <Menu.Item key="/files">
            <NavLink onClick={toggleCollapsed} to={`${path}/files`}>
              Todos
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/files/sent">
            <NavLink onClick={toggleCollapsed} to={`${path}/cards/files`}>
              Enviados
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/clientsFiles">
            <NavLink onClick={toggleCollapsed} to={`${path}/clients/files`}>
              Recebidos
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
        {isUserACLAllowed(identity, `${path}/tools`) && (
          <Menu.Item icon={!topMenu && <FeatherIcon icon="tool" />} key="tools">
            <NavLink onClick={toggleCollapsed} to={`${path}/tools`}>
              Ferramentas
            </NavLink>
          </Menu.Item>
        )}
        {isUserACLAllowed(identity, `${path}/reports`) && (
          <Menu.Item icon={!topMenu && <FeatherIcon icon="bar-chart-2" />} key="reports">
            <NavLink onClick={toggleCollapsed} to={`${path}/reports`}>
              Relatórios
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item icon={!topMenu && <FeatherIcon icon="shopping-cart" />} key="achievementProducts">
          <NavLink onClick={toggleCollapsed} to={`${path}/achievementProducts`}>
            Loja
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="cards" title="Cards">
        <Menu.Item icon={!topMenu && <FeatherIcon icon="layout" />} key="cardsSummary">
          <NavLink onClick={toggleCollapsed} to={`${path}/cards/summary/card_templates`}>
            Resumo de Cards
          </NavLink>
        </Menu.Item>
        <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="cards">
          <NavLink onClick={toggleCollapsed} to={`${path}/cards`}>
            Lista de Cards
          </NavLink>
        </Menu.Item>
        <Menu.Item icon={!topMenu && <FeatherIcon icon="tag" />} key="tickets">
          <NavLink onClick={toggleCollapsed} to={`${path}/cards/tickets`}>
            Lista de Tickets
          </NavLink>
        </Menu.Item>
        <Menu.Item icon={!topMenu && <FeatherIcon icon="map" />} key="cardMapViews">
          <NavLink onClick={toggleCollapsed} to={`${path}/cardMapViews`}>
            Mapa de Cards
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="rpa" title="Automações">
        {isUserACLAllowed(identity, `${path}/queuedJobs`) && (
          <Menu.Item icon={!topMenu && <FeatherIcon icon="database" />} key="queuedJobs">
            <NavLink onClick={toggleCollapsed} to={`${path}/queuedJobs`}>
              Fila de Tarefas
            </NavLink>
          </Menu.Item>
        )}
        {isUserACLAllowed(identity, `${path}/rpa`) && (
          <Menu.SubMenu key="rpa" icon={!topMenu && <RobotOutlined style={{ fontSize: 16 }} />} title="RPA">
            <Menu.Item key="sefazCeDteMessages">
              <NavLink onClick={toggleCollapsed} to={`${path}/rpa/sefazCeDteMessages`}>
                Mensagens do DTe
              </NavLink>
            </Menu.Item>
            <Menu.Item key="sefazCeSigetReports">
              <NavLink onClick={toggleCollapsed} to={`${path}/rpa/sefazCeSigetReports`}>
                Relatórios do Siget
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}
      </Menu.ItemGroup>

      <Menu.ItemGroup key="cadastros" title="Cadastros">
        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="clients">
          <NavLink onClick={toggleCollapsed} to={`${path}/clients`}>
            Clientes
          </NavLink>
        </Menu.Item>

        {isUserACLAllowed(identity, `${path}/achievements`) && (
          <Menu.Item icon={!topMenu && <FeatherIcon icon="award" />} key="achievements">
            <NavLink onClick={toggleCollapsed} to={`${path}/achievements`}>
              Conquistas
            </NavLink>
          </Menu.Item>
        )}

        {isUserACLAllowed(identity, `${path}/economicGroups`) && (
          <Menu.Item icon={!topMenu && <FeatherIcon icon="globe" />} key="economicGroups">
            <NavLink onClick={toggleCollapsed} to={`${path}/economicGroups`}>
              Grupos Empresariais
            </NavLink>
          </Menu.Item>
        )}

        {isUserACLAllowed(identity, `${path}/assets`) && (
          <Menu.Item icon={!topMenu && <BankOutlined style={{ fontSize: 16, color: 'white' }} />} key="assets">
            <NavLink onClick={toggleCollapsed} to={`${path}/assets`}>
              Patrimônios
            </NavLink>
          </Menu.Item>
        )}

        <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="users">
          <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
            Usuários
          </NavLink>
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup key="others" title="Outros">
        {isUserACLAllowed(identity, `${path}/fileCategories`) && (
          <Menu.SubMenu key="cadastros-arquivos" icon={!topMenu && <FeatherIcon icon="hard-drive" />} title="Arquivos">
            <Menu.Item key="fileCategories">
              <NavLink onClick={toggleCollapsed} to={`${path}/fileCategories`}>
                Categorias de Arquivos
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {(isUserACLAllowed(identity, `${path}/cardJustificationReasons`) ||
          isUserACLAllowed(identity, `${path}/cardTemplates`)) && (
          <Menu.SubMenu key="cadastros-cards" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Cards">
            {isUserACLAllowed(identity, `${path}/cardTemplates`) && (
              <Menu.Item key="cardTemplates">
                <NavLink onClick={toggleCollapsed} to={`${path}/cardTemplates`}>
                  Modelos de Cards
                </NavLink>
              </Menu.Item>
            )}
            {isUserACLAllowed(identity, `${path}/cardJustificationReasons`) && (
              <Menu.Item key="cardJustificationReasons">
                <NavLink onClick={toggleCollapsed} to={`${path}/cardJustificationReasons`}>
                  Motivos de Justificativa
                </NavLink>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}

        {isUserACLAllowed(identity, `${path}/assetCategories`) && (
          <Menu.SubMenu
            key="cadastros-assets"
            icon={!topMenu && <BankOutlined style={{ fontSize: 16, color: 'white' }} />}
            title="Patrimônios"
          >
            <Menu.Item key="assetCategories">
              <NavLink onClick={toggleCollapsed} to={`${path}/assetCategories`}>
                Categorias de Patrimônios
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        <Menu.Item
          icon={
            !topMenu && <img src="https://app.e-kontroll.com.br/imagens/logo.png" alt="e-Kontroll" key="e-kontroll" />
          }
          key="e-kontrol"
        >
          <Link to={{ pathname: 'https://app.e-kontroll.com.br/login' }} target="_blank">
            e-Kontroll
          </Link>
        </Menu.Item>

        <Menu.Item
          key="chatgpt"
          icon={
            !topMenu && (
              <svg style={{ width: 16, height: 16 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="#FFF"
                  d="M184 0c30.9 0 56 25.1 56 56l0 400c0 30.9-25.1 56-56 56c-28.9 0-52.7-21.9-55.7-50.1c-5.2 1.4-10.7 2.1-16.3 2.1c-35.3 0-64-28.7-64-64c0-7.4 1.3-14.6 3.6-21.2C21.4 367.4 0 338.2 0 304c0-31.9 18.7-59.5 45.8-72.3C37.1 220.8 32 207 32 192c0-30.7 21.6-56.3 50.4-62.6C80.8 123.9 80 118 80 112c0-29.9 20.6-55.1 48.3-62.1C131.3 21.9 155.1 0 184 0zM328 0c28.9 0 52.6 21.9 55.7 49.9c27.8 7 48.3 32.1 48.3 62.1c0 6-.8 11.9-2.4 17.4c28.8 6.2 50.4 31.9 50.4 62.6c0 15-5.1 28.8-13.8 39.7C493.3 244.5 512 272.1 512 304c0 34.2-21.4 63.4-51.6 74.8c2.3 6.6 3.6 13.8 3.6 21.2c0 35.3-28.7 64-64 64c-5.6 0-11.1-.7-16.3-2.1c-3 28.2-26.8 50.1-55.7 50.1c-30.9 0-56-25.1-56-56l0-400c0-30.9 25.1-56 56-56z"
                />
              </svg>
            )
          }
        >
          <NavLink onClick={toggleCollapsed} to={`${path}/chatgpt`}>
            Secran Brain
          </NavLink>
        </Menu.Item>

        {isUserACLAllowed(identity, `${path}/newsletters`) && (
          <Menu.Item
            icon={
              !topMenu && (
                <svg style={{ width: 16, height: 16 }} viewBox="0 0 24 24">
                  <path
                    fill="#FFF"
                    d="M20 2V4L4 8V6H2V18H4V16L6 16.5V18.5C6 20.4 7.6 22 9.5 22S13 20.4 13 18.5V18.3L20 20V22H22V2H20M11 18.5C11 19.3 10.3 20 9.5 20S8 19.3 8 18.5V17L11 17.8V18.5M20 18L4 14V10L20 6V18Z"
                  />
                </svg>
              )
            }
            key="newsletters"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/newsletters`}>
              Informativos
            </NavLink>
          </Menu.Item>
        )}
      </Menu.ItemGroup>
    </>
  );
};

MenuAdmin.propTypes = {
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuAdmin;
