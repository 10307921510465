import { List } from 'antd';
import Styled from 'styled-components';

const NotificationsList = Styled(List)`
  max-height: 400px;
  overflow: auto;

  .ant-list-items {
    padding-top: 0 !important;
    
    li:not(:last-child) {
      margin-bottom: 0 !important;
    }

    .ant-list-item {
      padding: 14px 8px;

      .ant-list-item-meta {
        align-items: center;
        padding: 0;
        border-bottom: none;

        .ant-list-item-meta-content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ant-list-item-meta-description {
            flex: 0 0 100px;
            text-align: right;
            padding-right: 8px;
          }
        }

        .ant-list-item-meta-avatar {
          margin: 0 10px 0 4px;
        }
  
        .ant-list-item-meta-title {
          margin-bottom: 0;
          color: ${({ theme }) => theme['dark-color']};
          
          a {
            padding: 0;
            
            &:hover {
              background: unset;
            }
              
            span {
              padding: 0;
            }
          }
        }
      }

      &.row-readed .ant-list-item-meta-title {
        color: ${({ theme }) => theme['gray-color']};
      }
    }
  }
`;

const NotificationsButton = Styled.div`
  margin-top: 12px;

  a {
    color: ${({ theme }) => theme['link-color']} !important;
    text-align: center;
  }
`;

export { NotificationsList, NotificationsButton };
